
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';

@Component({
    components: { CustomSelect },
})
export default class RatesClusterRoomTypeFilter extends Vue {
    @Inject(ClusterRatesServiceS)
    public clusterRatesService!: ClusterRatesService;

    @Inject(RatesFiltersServiceS)
    public ratesFiltersService!: RatesFiltersService;

    @Inject(RoomTypesServiceS)
    roomTypesService!: RoomTypesService;

    get value() {
        return this.clusterRatesService.storeState.settings.roomTypeId;
    }

    set value(value: number) {
        this.clusterRatesService.storeState.settings.roomTypeId = value;
        this.ratesFiltersService.settings.roomTypeId = value;
    }

    get items(): Item[] {
        return this.roomTypesService.rooms
            .map(room => ({
                value: room.id,
                name: room.name,
            }));
    }
}
