
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import moment from 'moment';

import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import WeekdayLetter from '@/modules/cluster/filters/weekend-letter.filter';

import ListHeader from '../../_common/list-days-header.vue';
import HotelsList from '../../_common/hotels-list/index.vue';
import RatesDaysSet from './rates-days-set.vue';

@Component({
    components: {
        ListHeader,
        HotelsList,
    },
    filters: {
        DateFilter: (value: (Date | null)) => {
            if (!value) return '';
            return moment(value).format('D/M');
        },

        WeekdayLetter,
    },
})
export default class RatesClusterList extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterRatesServiceS) private clusterRatesService!: ClusterRatesService;
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    get scoreSorting() {
        return this.clusterRatesService.scoreSorting;
    }

    get hotelNameSorting() {
        return this.clusterRatesService.hotelNameSorting;
    }

    get tableData() {
        if (this.hotels === null || !this.hotels.length) {
            return [];
        }

        return this.hotels.map(h => ({
            table: [{
                value: h.hotelName,
                onClick: () => this.toHotel(h),
            }, {
                value: h.totalScore,
            }, {
                value: h.newTotalScore !== 0
                    ? `${h.newTotalScore > 0 ? '+' : ''}${Math.round(h.newTotalScore)}`
                    : 0,

                class: h.newTotalScore && (h.newTotalScore > 0 ? 'green-change' : 'red-change'),
            }],
            component: {
                element: RatesDaysSet,
                props: { hotelData: h },
            },
        }));
    }

    get headerData() {
        return [
            {
                value: 'hotelName',
                name: 'Hotel name',
                sort: this.hotelNameSorting,
            },
            {
                value: 'totalScore',
                name: 'Rate Value Score',
                sort: this.scoreSorting,
            },
            {
                value: 'newTotalScore',
                name: 'Change',
            },
        ];
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    get hotels() {
        return this.clusterRatesService.hotels;
    }

    get pagesCount() {
        return this.clusterRatesService.hotelsCount;
    }

    handleSort(field: string) {
        switch (field) {
            case 'hotelName':
                this.clusterRatesService.toggleHotelNameSort();
                break;
            case 'totalScore':
                this.clusterRatesService.toggleScoreSort();
                break;
            default:
                break;
        }
    }

    private async toHotel(hotelData: ClusterHotelsRatesModel) {
        this.clusterRatesService.setActivePage('list');
        this.ratesService.saveDocument(null);
        this.documentFiltersService.savePos(null);

        const compsetId = hotelData.compsetMain?.id
            || hotelData.compsets[0]?.id;

        this.documentFiltersService.compsetIdFromHighLevel = compsetId;
        this.documentFiltersService.compsetId = compsetId;
        this.ratesFiltersService.settings.provider = this.clusterService.currentRatesProvider;

        this.ratesFiltersService.providerFromHighLevel = this.clusterService.currentRatesProvider;
        await this.$router.push({
            name: `${this.$route.name!}.hotel`,
            params: {
                hotelId: String(hotelData.hotelId),
            },
        });
    }
}
